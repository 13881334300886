<template>
  <div class="navbar-container d-flex content align-items-center">
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>
    <h6 style="margin-top: 10px">
      <b> {{ company }}</b>
    </h6>

    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    ></div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-dropdown
        id="user-status-dropdown"
        variant="outline-success"
        class="mb-0 mr-3"
        v-if="connectionState == 'Connected'"
        @show="getHubUser()"
      >
        <template #button-content>
          <span>
            <strong>{{ isStatus }}</strong>
          </span>
        </template>

        <b-dropdown-item v-for="(user, index) in connectedUsers" :key="index">
          <feather-icon
            icon="SettingsIcon"
            class="spinner mr-1"
            style="color: green"
          />{{ user.clientId }}
        </b-dropdown-item>
      </b-dropdown>
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">{{ fullName }}</p>
            <span class="user-status">{{ title }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="avatar"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <!-- <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="settings()"
        >
          <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
          <span>Ayarlar</span>
        </b-dropdown-item> -->
        <!-- <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="pricing()"
        >
          <feather-icon size="16" icon="CreditCardIcon" class="mr-50" />
          <span>Pricing</span>
        </b-dropdown-item> -->
        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Çıkış</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import signalRService from "../../signalR";
export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      connectionState: signalRService.connection.state, // Başlangıç durumu
      connectionId: null, // SignalR bağlantı kimliği
      connectedUsers: [], // Bağlı diğer kullanıcılar,
      fullName: "",
      title: "",
      company: "",
      avatar:
        "https://www.shareicon.net/data/2017/02/15/878685_user_512x512.png",
    };
  },
  computed: {
    isStatus() {
      switch (this.connectionState) {
        case "Connected":
          return "Bağlantılar";
        case "Reconnecting":
          return "Yeniden Bağlanıyor";
        case "Disconnected":
          return "Bağlantı Kesildi";
        default:
          return "Bilinmiyor";
      }
    },
  },
  async created() {
    // SignalR bağlantısının hazır olmasını bekle
    await signalRService.isReady;
    // SignalR bağlantı durumu değişikliklerini dinle
    signalRService.connection.onreconnecting(() => {
      // console.log("SignalR yeniden bağlanıyor...");
      this.checkConnectionState();
    });
    signalRService.connection.onreconnected(() => {
      // console.log("SignalR yeniden bağlandı.");
      this.checkConnectionState();
    });

    signalRService.connection.onclose(() => {
      // console.log("SignalR bağlantısı tamamen kapandı.");
      this.checkConnectionState();
    });
  },
  beforeDestroy() {
    clearInterval(this.interval); // Bileşen yok edilirken interval'i temizle
  },
  async mounted() {
    this.checkConnectionState(); // İlk kontrol
    this.interval = setInterval(this.checkConnectionState, 2000); // Her 1 saniyede kontrol
    //
    var userData = JSON.parse(localStorage.getItem("RPUserData"));
    this.fullName = userData.fullName;
    this.title = userData.title;
    this.company = userData.customer.customerName;
    if (
      userData.avatar != null ||
      userData.avatar != undefined ||
      userData.avatar != ""
    ) {
      this.avatar = userData.avatar;
    }
    if (this.avatar == "") {
      this.avatar =
        "https://www.shareicon.net/data/2017/02/15/878685_user_512x512.png";
    }
  },
  methods: {
    async getHubUser() {
      this.$http
        .get("hub/clients")
        .then((response) => {
          this.connectedUsers = response.data;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    checkConnectionState() {
      this.connectionState = signalRService.connection.state; // Durumu güncelle
    },

    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("RPUserData");
      localStorage.removeItem("RPaccessToken");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: "login" });
    },
    settings() {
      this.$router.push({ name: "settings" });
    },
  },
};
</script>
